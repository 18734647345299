/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image, Button, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Šatní skříně"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-z40l1t --parallax pb--80 pt--80" name={"kontakt"} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/3128d434b3f340779b88358afc136615_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/3128d434b3f340779b88358afc136615_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/3128d434b3f340779b88358afc136615_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/3128d434b3f340779b88358afc136615_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/3128d434b3f340779b88358afc136615_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/3128d434b3f340779b88358afc136615_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/3128d434b3f340779b88358afc136615_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/3128d434b3f340779b88358afc136615_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2" anim={"7"} animS={"2"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: rgb(255, 255, 255);\">Šatní skříně</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"gldri53pug"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":678}}>
              
              <Title className="title-box" style={{"maxWidth":930}} content={"Šatní skříně jako dokonalé řešení šaten s úžasnými dekory v  perfektním provedení nábytku."}>
              </Title>

              <Text className="text-box" style={{"maxWidth":649}} content={" Jsou ideálním předělem místností nebo naopak umožňují harmonické rozšíření obytného prostoru. Nabízíme výsuvná i závěsná řešení pro nejefektivnější využití skříní."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w pb--80" name={"1d8u6246rn5"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/10513/735f9c951802419e97b984c32cf99930_s=860x_.JPG"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/10513/735f9c951802419e97b984c32cf99930_s=350x_.JPG 350w, https://cdn.swbpg.com/t/10513/735f9c951802419e97b984c32cf99930_s=660x_.JPG 660w, https://cdn.swbpg.com/t/10513/735f9c951802419e97b984c32cf99930_s=860x_.JPG 860w, https://cdn.swbpg.com/t/10513/735f9c951802419e97b984c32cf99930_s=1400x_.JPG 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/10513/f15bd1620bb9461098af3a3a6a351eb7_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/10513/f15bd1620bb9461098af3a3a6a351eb7_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/f15bd1620bb9461098af3a3a6a351eb7_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/f15bd1620bb9461098af3a3a6a351eb7_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10513/f15bd1620bb9461098af3a3a6a351eb7_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/10513/3810b93a36c44fd3a852a92ba8a34f00_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/10513/3810b93a36c44fd3a852a92ba8a34f00_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/3810b93a36c44fd3a852a92ba8a34f00_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/3810b93a36c44fd3a852a92ba8a34f00_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10513/3810b93a36c44fd3a852a92ba8a34f00_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/10513/f5073c7376b6436ca26d0413451791bd_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/10513/f5073c7376b6436ca26d0413451791bd_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/f5073c7376b6436ca26d0413451791bd_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/f5073c7376b6436ca26d0413451791bd_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10513/f5073c7376b6436ca26d0413451791bd_s=1400x_.jpg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/10513/2154bd9c16d24329a2d5ddb833c2e7eb_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":1000}} srcSet={"https://cdn.swbpg.com/t/10513/2154bd9c16d24329a2d5ddb833c2e7eb_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/2154bd9c16d24329a2d5ddb833c2e7eb_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/2154bd9c16d24329a2d5ddb833c2e7eb_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10513/2154bd9c16d24329a2d5ddb833c2e7eb_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/10513/ca2406415139405488dc97ec2a0a7242_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/10513/ca2406415139405488dc97ec2a0a7242_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/ca2406415139405488dc97ec2a0a7242_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/ca2406415139405488dc97ec2a0a7242_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10513/ca2406415139405488dc97ec2a0a7242_s=1400x_.jpg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/10513/71f613ca8fc444cda6d1ba2700afa6e6_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":1000}} srcSet={"https://cdn.swbpg.com/t/10513/71f613ca8fc444cda6d1ba2700afa6e6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/71f613ca8fc444cda6d1ba2700afa6e6_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/71f613ca8fc444cda6d1ba2700afa6e6_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10513/71f613ca8fc444cda6d1ba2700afa6e6_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/10513/e0c25f14bee44f99a4aa5fef49109557_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/10513/e0c25f14bee44f99a4aa5fef49109557_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/e0c25f14bee44f99a4aa5fef49109557_s=660x_.jpg 660w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/10513/a9cdec75a5f04eb7a4d21a5f4bf7f271_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":1000}} srcSet={"https://cdn.swbpg.com/t/10513/a9cdec75a5f04eb7a4d21a5f4bf7f271_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/a9cdec75a5f04eb7a4d21a5f4bf7f271_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/a9cdec75a5f04eb7a4d21a5f4bf7f271_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10513/a9cdec75a5f04eb7a4d21a5f4bf7f271_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/10513/a9cdec75a5f04eb7a4d21a5f4bf7f271_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1l79c6x css-42e4bw --parallax pb--80 pt--80" name={"kontakt"} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/b231b74ac47d41c59e63ab1bc8987342_r=810__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/b231b74ac47d41c59e63ab1bc8987342_r=810__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/b231b74ac47d41c59e63ab1bc8987342_r=810__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/b231b74ac47d41c59e63ab1bc8987342_r=810__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/b231b74ac47d41c59e63ab1bc8987342_r=810__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/b231b74ac47d41c59e63ab1bc8987342_r=810__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/b231b74ac47d41c59e63ab1bc8987342_r=810__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/b231b74ac47d41c59e63ab1bc8987342_r=810__s=3000x_.jpg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2" anim={"7"} animS={"2"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: rgb(255, 255, 255);\">Máte zájem o šatní skříně?</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--center mt--16" use={"page"} href={"/kontakt"} content={"Kontaktovat&nbsp; ➔"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3 flex--center" anim={"2"} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box w--500" content={"<span style=\"color: var(--color-dominant);\">Living 4 Handy</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":224}} content={"<span style=\"color: rgb(220, 221, 221);\">Výrobce kvalitních kuchyní a nábytku na míru pro Vaši spokojenost. Realizujeme  po Praze, Středočeském kraji i okolí.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--center" style={{"maxWidth":473}} content={"<span style=\"color: rgb(248, 248, 248);\">IČ 25731491<br>DIČ CZ25731491<br><br>Společnost je vedená u Městského soudu v Praze, spisová značka C 65152</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box w--500 lh--14" content={"<span style=\"color: var(--color-dominant);\">+420 777 206 285<br>info@living4handy.cz<br></span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: rgb(220, 221, 221);\">Living4Handy s.r.o.\n<br>Luženská 2801<br>&nbsp;Rakovník</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}